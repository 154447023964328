
.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand > img {
    padding: 7px 14px;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* ===================================================================
 *  navbar styles 
 *
 * ------------------------------------------------------------------- */
.navbar-nav > li {
    color: black;
    background-color: transparent;
}

    .navbar-nav > li:hover,
    .navbar-nav > li.active,
    .dropdown:hover, .dropbtn:hover, dropdown-menu:hover {
        color: black;
        font-weight: bold;
        background-color: whitesmoke;
        border-radius: 10px;
    }


/* ===================================================================
 *  Dropdown Menu 
 *
 * ------------------------------------------------------------------- */
/*.dropdown {
    float: left;
    overflow: hidden;
}

    .dropdown .dropbtn {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding: 14px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
    }

    .navbar a:hover, .dropdown:hover .dropbtn {
        background-color: red;
    }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }

        .dropdown-content a:hover {
            background-color: #ddd;
        }

.dropdown:hover .dropdown-content {
    display: block;
}*/

/* ===================================================================
 *  09. header styles 
 *
 * ------------------------------------------------------------------- */
header {
    width: 100%;
    background: #DBDBDB url(../img/escheresque_@2X.png) repeat;
    background-size: 46px 29px;
}
    /*height: 130px;
    border-bottom: 1px solid #E5E5E5;
}

    header .header-content {
        //height: auto;
        max-width: 1290px;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        width: 100%;
    }

    header a {
        border: none;
    }

@media only screen and (max-width:400px) {
    header {
        height: 118px;
    }
}
    */