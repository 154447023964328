/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
    margin: 3px;
    height: 36px;
    margin-left: 0px;
    padding: 6px 9px
}

.btn-laudar {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 6px;
    text-align: center;
    color: #fff;
    background-color: #09e688;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
}

.btn-videos {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 4px;
    text-align: center;
    color: #fff;
    background-color: forestgreen;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
}

.btn-print {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 5px;
    text-align: center;
    color: #fff;
    background-color: #a0a0a0;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
}

.btn-edit {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 5px;
    text-align: center;
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
}

.btn-checked {
    margin: 0px 0px 0px 3px;
    padding: 0px 0px 3px 0px;
    text-align: center;
    color: #fff;
    background-color: #1bc26e;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
    font-family: 'Verdana';
}

.btn-unchecked {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 3px;
    text-align: center;
    color: #fff;
    background-color: white;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
}

.btn-edit-l {
    padding-left: 7px;
    width: 47px;
}

.btn-del {
    margin: 0px 0px 0px 3px;
    padding-top: 0px;
    padding-left: 5px;
    text-align: center;
    color: #fff;
    background-color: #c26e1b;
    border-color: #1861ac;
    width: 19px;
    height: 19px;
    font-size: 12px;
    font-weight: bolder;
}

.btn-del-l {
    padding-left: 3px;
    width: 47px;
}

.action {
    color: #0366d6;
    text-decoration: underline; 
    cursor: pointer;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: whitesmoke;
}

.containere{
    background-color: greenyellow;
}
.home-image {
    margin: 0px;
    padding: 0px;
    width: 100%; /* com isso imagem ocupar� toda a largura da tela. Se colocarmos height: 100% tamb�m, a imagem ir� distorcer */
    //height: 650px;
    //background-position: center;
    overflow: hidden; /* para que n�o tenha rolagem se a imagem de fundo for maior que a tela */
    background-image: url("./img/docdocs.jpg");
    //background-color: #cccccc;
    //background-repeat: no-repeat;
    //background-size: cover;
    //position: relative;
    position: absolute;
    /*top: 44px;*/
    top: auto;
    left: 0px;
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.right-image {
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 30px;
    right: 30px;
}

.bottom-left-text {
    margin: 0px;
    padding: 0px;
    position: absolute;
    left: 50px;
    bottom: 50px;
    color: black;
    font-weight: bolder;
    font-size: 200%;
}

.home-text {
    text-align: center;
    position: absolute;
    top: 25%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: black;
    font-weight: bolder;
    font-size: 200%;
}

.company-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: black;
    font-weight: bolder;
    font-size: 130%;
}

.company-nav {
    font-weight: bolder;
    font-size: 150%;
}

.quadro {
    width: 640px;
    height: 360px;
    border: 1px solid #000;
}

.fill {
    object-fit: fill;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

.none {
    object-fit: none;
}

.scale-down {
    object-fit: scale-down;
}
