.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
    min-height: 140px;
}

    .editor :global(.public-DraftEditor-content) {
        min-height: 140px;
    }

.headlineButtonWrapper {
    display: inline-block;
}

.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
}

    .headlineButton:hover,
    .headlineButton:focus {
        background: #f3f3f3;
    }
